<template>
  <div class="bgBox">
    <img src="../../assets/img/bg/bg.jpg" class="topImg" alt="">
    <!-- <div class="Box">
      <div class="backImg">
        <div class="logoBox">
          <div class="logo_img">
            <img src="../../assets/img/logo/logo1.png" alt />
          </div>
          <div class="title_Box">
            <p>瑜璟缘国际文化礼仪培训</p>
          </div>
        </div>
      </div> -->

      <div class="nav">
        <el-dropdown>
           <span class="title" @click="goRouter('/home')">
            首页
            </span>
            <el-dropdown-menu style="opacity: 0"  slot="dropdown"></el-dropdown-menu>
        </el-dropdown>
        <el-dropdown>
          <span @click="goRouter('/IBIE')">IBIE国际测评与认证</span>
          <el-dropdown-menu style="opacity: 0" slot="dropdown"></el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command='goRouter'>
          <span>企业礼仪培训<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="/attestation">培训课程</el-dropdown-item> -->
            <el-dropdown-item command="/etiquetteTraining?tag=swly">商务礼仪</el-dropdown-item>
            <el-dropdown-item command="/etiquetteTraining?tag=jdly">接待礼仪</el-dropdown-item>
            <el-dropdown-item command="/etiquetteTraining?tag=fwly">服务礼仪</el-dropdown-item>
            <el-dropdown-item command="/etiquetteTraining?tag=wyly">物业礼仪</el-dropdown-item>
            <el-dropdown-item command="/etiquetteTraining?tag=ylly">医疗礼仪</el-dropdown-item>
            <el-dropdown-item command="/etiquetteTraining?tag=ymly">医美礼仪</el-dropdown-item>
            <el-dropdown-item command="/etiquetteTraining?tag=dcly">地产礼仪</el-dropdown-item>
            <el-dropdown-item command="/etiquetteTraining?tag=zwly">政务礼仪</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command='goRouter'>
          <span @click="goRouter('/course')">精品课程<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="/teaching?tag=gjxx">国际礼仪班</el-dropdown-item>
            <el-dropdown-item command="/teaching?tag=swly">商务礼仪班</el-dropdown-item> -->
            <el-dropdown-item command="/course/detail?tag=gjxxlys">国际形象礼仪师培训</el-dropdown-item>
            <el-dropdown-item command="/course/detail?tag=gjxxgw">国际形象顾问培训</el-dropdown-item>
            <el-dropdown-item command="/course/detail?tag=gjlys">国际礼仪师培训</el-dropdown-item>
            <el-dropdown-item command="/course/detail?tag=cslm">城市联盟机构培训</el-dropdown-item>
            <el-dropdown-item command="/course/detail?tag=gjsw">国际商务礼仪精英认证（线上）</el-dropdown-item>
            <el-dropdown-item command="/course/detail?tag=grly">个人礼仪培训</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command='goRouter'>
          <span @click="goRouter('')">名师授课<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="">创始人介绍</el-dropdown-item>
            <el-dropdown-item command="/famousTeacher?tag=zj">专家讲师介绍</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command='goRouter'>
          <span @click="goRouter('/journalism')">新闻动态<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/journalism">行业新闻</el-dropdown-item>
            <el-dropdown-item command="/excellent?type=3">学员风采</el-dropdown-item>
            <el-dropdown-item command="/excellent?type=2">企业培训案例</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command='goRouter'>
          <span @click="goRouter('')">关于我们<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/companySynopsis">企业简介</el-dropdown-item>
            <el-dropdown-item command="/invite">人才招聘</el-dropdown-item>
            <el-dropdown-item command="">积分查询</el-dropdown-item>
            <el-dropdown-item command="/my">联系我们</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    goRouter(url) {
      if(url) {
         this.$router.push(url);
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.bgBox {
  width: 100%;
 /*  background-image: url("../../assets/img/bg/2165324.png");
  //background-attachment: fixed;
  background-repeat: repeat-x;
  background-size: 100% 160px; */
  .topImg{
    width: 100%;
  }
  .nav {
     width: 1200px;
     margin: 20px auto;
    display: flex;
    height: 60px;
    line-height: 60px;
    background: #606266;
    .el-dropdown{
      flex: 1;
      text-align: center;
      color: #fff;
      cursor: pointer;
      .title{
        width: 100%;
        display: inline-block;
      }
    }
  }
 }
.Box {
  overflow: hidden;
}
.backImg {
  height: 160px;
  width: 100%;
  .logoBox {
    width: 1200px;
    height: 160px;
    margin: 0 auto;
    // background-color: red;

    overflow: hidden;
    .logo_img {
      height: 130px;
      width: 150px;
      // background-color: antiquewhite;
      margin: 5px;
      float: left;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title_Box {
      float: left;
      margin-left: 100px;
      margin-top: 30px;
      p {
        width: 600px;
        font-size: 30px;
        margin: 20px 0;
        text-align: center;
        font-weight: bold;
        color: rgb(253, 236, 189);
      }
    }
  }
}
.navigation {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: cornflowerblue;
}
.el-menu-demo {
  .el-menu-item {
    width: 14.2%;
    text-align: center;
  }
}

.el-submenu {
  text-align: center;
  width: 14.2%;
}
.el-menu--horizontal {
  width: 100%;
}
</style>
