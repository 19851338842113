import { render, staticRenderFns } from "./tail.vue?vue&type=template&id=b80af840&scoped=true&"
var script = {}
import style0 from "./tail.vue?vue&type=style&index=0&id=b80af840&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b80af840",
  null
  
)

export default component.exports