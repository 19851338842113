import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  //首页
  {
    path: '/home',
    meta:{
      title:"首页"
    },
    component: r => require.ensure([], () => r(require('@/page/home/home.vue')), 'pointzujinListEdit')
  },
  //公司简介
  {
    path: '/companySynopsis',
    meta:{
      title:"公司简介"
    },
    component: r => require.ensure([], () => r(require('@/page/synopsis/companySynopsis.vue')), 'companySynopsis')
  },
  //预约咨询
  {
    path: '/consult',
    meta:{
      title:"预约咨询"
    },
    component: r => require.ensure([], () => r(require('@/components/consult/consult.vue')), 'consult')
  },
  //课程中心-认证课程
  {
    path: '/attestation',
    meta:{
      title:"认证课程"
    },
    component: r => require.ensure([], () => r(require('@/page/coursCeentre/attestation.vue')), 'attestation')
  },
  //课程中心-认证指南
  {
    path: '/attestationFingerpost',
    meta:{
      title:"认证指南"
    },
    component: r => require.ensure([], () => r(require('@/page/coursCeentre/attestationFingerpost.vue')), 'attestationFingerpost')
  },
  //课程中心-证书介绍
  {
    path: '/certificate',
    meta:{
      title:"证书介绍"
    },
    component: r => require.ensure([], () => r(require('@/page/coursCeentre/certificate.vue')), 'certificate')
  },
  //IBIE国际测评与认证
  {
    path: '/IBIE',
    meta:{
      title:"IBIE国际测评与认证"
    },
    component: r => require.ensure([], () => r(require('@/page/IBIE/IBIE.vue')), 'IBIE')
  },
  //名师授课
  {
    path: '/famousTeacher',
    meta:{
      title:"名师授课"
    },
    component: r => require.ensure([], () => r(require('@/page/famousTeacher/teacher.vue')), 'famousTeacher')
  },
  /* //师资中心-国外名师
  {
    path: '/foreign',
    meta:{
      title:"国外名师"
    },
    component: r => require.ensure([], () => r(require('@/page/teaching/foreign.vue')), 'foreign')
  }, */
  // 讲师介绍
  /* {
    path: '/teaching',
    meta:{
      title:"讲师介绍"
    },
    component: r => require.ensure([], () => r(require('@/page/teaching/teaching.vue')), 'foreign')
  },
  {
    path: '/teaching/detail',
    meta:{
      title:"讲师介绍详情"
    },
    component: r => require.ensure([], () => r(require('@/page/teaching/detail.vue')), 'foreign')
  }, */
  {
    path: '/course',
    meta:{
      title:"精品课程"
    },
    component: r => require.ensure([], () => r(require('@/page/course/course.vue')), 'foreign')
  },
  {
    path: '/course/detail',
    meta:{
      title:"精品课程详情"
    },
    component: r => require.ensure([], () => r(require('@/page/course/detail.vue')), 'foreign')
  },
  {
    path: '/etiquetteTraining',
    meta:{
      title:"企业礼仪培训"
    },
    component: r => require.ensure([], () => r(require('@/page/etiquetteTraining/etiquetteTraining.vue')), 'foreign')
  },
  //企业培训-合作伙伴
  {
    path: '/partner',
    meta:{
      title:"合作伙伴"
    },
    component: r => require.ensure([], () => r(require('@/page/cultivate/partner.vue')), 'foreign')
  },
  //企业培训-优秀案例
  {
    path: '/excellent',
    meta:{
      title:"优秀案例"
    },
    component: r => require.ensure([], () => r(require('@/page/cultivate/excellent.vue')), 'excellent')
  },
   //企业培训-优秀案例
   {
    path: '/particulars/:ifjournalism/:id',
    name: 'particulars',
    meta:{
      title:"案例详情"
    },
    component: r => require.ensure([], () => r(require('@/page/cultivate/particulars.vue')), 'particulars')
  },
  //新闻咨询
  {
    path: '/journalism',
    meta:{
      title:"新闻咨询"
    },
    component: r => require.ensure([], () => r(require('@/page/journalism/journalism.vue')), 'journalism')
  },
  //我要应聘
  {
    path: '/invite',
    meta:{
      title:"我要应聘"
    },
    component: r => require.ensure([], () => r(require('@/page/serve/invite.vue')), 'invite')
  },
  //联系我们
  {
    path: '/my',
    meta:{
      title:"联系我们"
    },
    component: r => require.ensure([], () => r(require('@/page/my/my.vue')), 'my')
  },
   //首页
   {
    path: '*',
    meta:{
      title:"首页"
    },
    component: r => require.ensure([], () => r(require('@/page/home/home.vue')), 'pointzujinListEdit')
  },
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  /* if (to.meta.title) {
    document.title = to.meta.title
  } */
  let head = document.getElementsByTagName('head');
  let meta = document.createElement('meta');
  document.querySelector('meta[name="keywords"]').setAttribute('content', '礼仪培训师资格证,线上礼仪培训班,礼仪培训网课,形象礼仪培训师在线考证')
  document.querySelector('meta[name="description"]').setAttribute('content', '瑜璟缘国际形象礼仪主营：礼仪培训,礼仪培训师,商务礼仪培训,社交礼仪培训,服务礼仪培训,企业礼仪培训,职场礼仪培训,礼仪培训班,政务礼仪培训的培训团队。电话：13121112777欢迎您来电咨询！')
  head[0].appendChild(meta)
  document.title = '瑜璟缘国际形象礼仪，国际礼仪培训机构，企业培训机构，形象礼仪，形象礼仪培训师'
  next()
})
export default router
