<template>
  <div>
    <myhead />
    <router-view></router-view>
    <div ref="drawer" class="drawer" @mouseenter="enter" @mouseleave="out">
      <div class="goTop" @click="goTop">
       <div class="img">
          <img  src="./assets/img/xf/goTop.png" />
       </div>
        <div class="text">返回顶部</div>
      </div>
      <div class="phone">
        <div class="img">
          <img src="./assets/img/xf/phone.png" alt="">
        </div>
        <div class="text">13121112777</div>
      </div>

    <div class="qq">
      <a  href="http://wpa.qq.com/msgrd?v=3&uin=30397378&site=qq&menu=yes" target="view_window" class="Qq">
        <div class="img">
          <img src="./assets/img/xf/qq.png" alt="">
        </div>
        <div class="text">QQ</div>
      </a>
      </div>

      <div class="Vx">
        <div class="img">
          <img src="./assets/img/xf/rqCode.png" alt="">
        </div>
        <div class="text">WeChat</div>
      </div>
      <div class="rqCode" v-if="rQCodeShow">
          <img src="./assets/img/qrCode/home.png" alt="">
      </div>
    </div>
    <tail />
  </div>
</template>

<script>
import tail from "@/components/headTail/tail";
import myhead from "@/components/headTail/head";
export default {
  components: {
    tail,
    myhead,
   
  },
  data() {
    return {
       rQCodeShow:false,
    }
  },
  methods: {
    enter() {
      console.log(1);
      this.rQCodeShow = true
      const drawer = this.$refs.drawer;
      drawer.style.right = 0;
    },
    out() {
      this.rQCodeShow = false
      const drawer = this.$refs.drawer;
      drawer.style.right = "-200px";
    },
    goTop(){
      window.scroll(0, 0) //滚动条返回顶部
    }
  },
};
</script>



<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}

.app-enter-active,
.app-leave-active {
  transition: 0.5s;
}

.app-enter,
.app-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.Box {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.leftBox {
  width: 80%;
  float: left;
}
.drawer {
  position: fixed;
  z-index: 1888;
  right: -200px;
  top: 20%;
  overflow: hidden;
  .goTop {
    background-color: rgb(41, 42, 86);
    overflow: hidden;
    margin-bottom: 10px;
    height: 70px;
    .img {
      float: left;
      width: 70px;
      height: 70px;
      img{
        width: 34px;
        height: 34px;
        margin:18px
      }
    }
    .text {
      width: 200px;
      float: left;
      height: 70px;
      line-height: 70px;
      color: #fff;
    }
  }
  .phone {
    background-color: rgb(41, 42, 86);
    overflow: hidden;
    margin-bottom: 10px;
    .img {
      float: left;
      width: 70px;
      height: 70px;
      img{
        width: 34px;
        height: 34px;
        margin:18px
      }
    }
    .text {
      float: left;
      height: 70px;
      line-height: 70px;
      text-align: center;
      color: #fff;
    }
  }
  .qq {
    background-color: rgb(41, 42, 86);
    overflow: hidden;
    margin-bottom: 10px;
    .img {
      float: left;
      width: 70px;
      height: 70px;
      img{
        width: 34px;
        height: 34px;
        margin:18px
      }
    }
    .text {
      float: left;
      height: 70px;
      line-height: 70px;
      text-align: center;
      color: #fff;
    }
  }
  .Vx {
    background-color: rgb(41, 42, 86);
    overflow: hidden;

    .img {
      float: left;
      width: 70px;
      height: 70px;
      img{
        width: 34px;
        height: 34px;
        margin:18px
      }
    }
    .text {
      float: left;
      height: 70px;
      line-height: 70px;
      text-align: center;
      color: #fff;
    }
  }
  .rqCode{
    background-color: rgb(41, 42, 86);
    img{
      margin-left: 18px;
    }
  }
}
</style>
